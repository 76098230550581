import styled from 'styled-components';

const StyledMessageBox = styled.div<{ isFailure: boolean }>`
    text-align: center;
    padding: 1.2rem;
    margin: .5rem 0;
    background:
    ${props => props.isFailure
        ? props.theme.denyColor
        : props.theme.confirmColor
    };
    color: ${props => props.theme.white1};
    `;

interface Props {
    message: string,
    isFailure?: boolean,
}

const MessageBox = ({ message, isFailure = false }: Props): JSX.Element | null => {
    if (message !== "") {
        return <StyledMessageBox isFailure={isFailure}>{message}</StyledMessageBox>
    } else {
        return null;
    }
};

export default MessageBox;