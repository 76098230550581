import styled from 'styled-components';
import { devices } from '../helpers/devices';
import iconeTel from '../assets/tel-icon.png';
import iconeWhatsapp from '../assets/whats-icon.png';

const StyledContatoItem = styled.div`
    color: ${props => props.theme.white1};

    & ul {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
    }

    @media ${devices.laptop} {
        display: none;
    }
    `;

const Mobile = styled.div`
    display: none;
    margin: 0 2rem;
    color: ${props => props.theme.white1};

    & h3 {
        margin: 2rem;
    }

    & ul {
        display: flex;
        flex-direction: column;
    }

    @media ${devices.laptop} {
        display: flex;
        justify-content: space-around;
        align-items: center;
        grid-area: tel;
    }

    @media ${devices.tablet} {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & h3 {
            margin: 1rem 0;
        }
    }
    `;

const Span = styled.span`
    display: flex;
    align-items: center;
    margin-bottom: .4rem;

    & a {
        color: ${props => props.theme.white1};
        text-decoration: none;
    }

    & img {
        margin-right: .5rem;
    }
    `;

const ContatoItem = (): JSX.Element => (
    <StyledContatoItem>
        <h3>Rua Líbero Badaró, 425 - 29° Andar - São Paulo/SP</h3>
        <ul>
            <Span><img src={iconeTel} alt="Ícone de telefone" /><a href="tel:+551131129292">: 11 3112-9292</a></Span>
            <Span><img src={iconeWhatsapp} alt="Ícone de WhatsApp" /><a href="tel:+55119927747556">: 11 99277-4756</a></Span>
        </ul>
    </StyledContatoItem>
);

const MobileContatoItem = (): JSX.Element => (
    <Mobile>
        <h3>Rua Líbero Badaró, 425 - 29° Andar - São Paulo/SP</h3>
        <ul>
            <Span><img src={iconeTel} alt="Ícone de telefone" /> : <a href="tel:+551131129292">11 3112-9292</a></Span>
            <Span><img src={iconeWhatsapp} alt="Ícone de WhatsApp" /> : <a href="tel:+55119927747556">11 99277-4756</a></Span>
        </ul>
    </Mobile>
);

export { ContatoItem, MobileContatoItem };