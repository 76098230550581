import { useState } from 'react';
import styled from 'styled-components';
import WomanImgMobile from '../assets/fala 4.png';
import WomanImg from '../assets/how tipe.png';
import { devices } from '../helpers/devices';
import BotaoCallToAction from "./BotaoCallToAction";
import MessageBox from './MessageBox';
import useScrollTo from '../helpers/useScrollTo';
import axios from 'axios';

const Section = styled.section`
    display: grid;
    grid-template-areas:
    "header  header"
    "contact img";
    grid-column-gap: 1rem;
    margin: 0 8rem 2rem 8rem;

    & p {
        margin-top: .5rem;
    }

    @media ${devices.tablet} {
        grid-template-areas:
        "header  header header"
        "contact contact contact"
        ".       img     .";
        margin: 0 3rem 0 3rem;
    }

    @media ${devices.mobileL} {
        margin: 0 .5rem 0 .5rem;
    }
    `;

const H1 = styled.h1`
    grid-area: header;
    color: ${props => props.theme.headerTextColor1};
    font-size: 3rem;

    @media ${devices.mobileL} {
        font-size: 2rem;
    }
    `;

const SectionLeft = styled.div`
    grid-area: contact;

    & form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & [type=file] {
            padding: 3rem;
            text-align: center;

            @media ${devices.mobileL} {
                text-align: left;
            }
        }

        & button {
            margin-top: 2rem;
        }
    }

    @media ${devices.tablet} {
        padding-right: 0;

        & button {
            align-self: center;
        }
    }
    `;

const Input = styled.input`
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    margin-top: 1.5rem;
    border-radius: 0;
    box-shadow: inset 1px 1px 6px ${props => props.theme.gray3};
    border: none;

    @media ${devices.tablet} {
        max-width: 100%;
    }
    `;

const SectionRight = styled.div`
    grid-area: img;
    `;

const Img = styled.img`
    width: 100%;
    height: auto;

    @media ${devices.tablet} {
        display: none;
    }
    `;

const ImgMobile = styled.img`
    display: none;
    width: 100%;
    height: auto;

    @media ${devices.tablet} {
        margin-top: 2rem;

        display: block;
    }
    `;

const Highlight = styled.span`
    color: ${props => props.theme.headerTextColor1};
    `;

const ParceiroSection = (): JSX.Element => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [file, setFile] = useState<File | null >(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [confirmMessage, setConfirmMessage] = useState<string>("");

    const applyTimeout = (message: string, callback: Function): void => {
        callback(message);

        setTimeout(() => {
            callback("");
        }, 9000);
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fileList = event.target.files;
        
        if (fileList != null) {
            if (fileList[0].type !== "application/pdf") {
                setFile(null);
                applyTimeout("É necessário que o seu arquivo seja do tipo '.pdf'!", setErrorMessage);
            } else {
                setErrorMessage("");
                setFile(fileList[0]);
            }
        } else {
            setFile(null);
        }
    };

    const handleFormSent = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (file == null) {
            applyTimeout("Por favor, selecione um arquivo com formato '.pdf'!", setErrorMessage);
        } else {
            const data = {
                name: name,
                email: email,
                phone: phone,
                file: file,
            }

            try {
                const response = await axios.post("https://service.dascam.com.br/contact", data);
                console.log(response);

                setErrorMessage("");
                applyTimeout("Seus dados foram enviados com sucesso!", setConfirmMessage);
            } catch (error) {
                console.log(error);
                applyTimeout("Não foi possível enviar os dados, tente novamente mais tarde.", setErrorMessage)
            }
        }
    };
    const h1Ref = useScrollTo<HTMLHeadingElement>('', { behavior: "smooth", block: "start" });

    return (
        <Section>
            <H1 ref={h1Ref}>Seja um parceiro Dascam</H1>
            <SectionLeft>
                <p>Se tem interesse em trabalhar com nossa equipe deixe seus dados que entraremos em contato breve! <Highlight>Caso seja selecionado(a)</Highlight></p>
                <MessageBox message={errorMessage} isFailure={true}/>
                <MessageBox message={confirmMessage} isFailure={false}/>
                <form id="contact-form" onSubmit={event => { handleFormSent(event); }}>
                    <Input
                        value={name}
                        required
                        autoFocus
                        placeholder="Nome"
                        id="Nome"
                        type="text"
                        minLength={2}
                        form="contact-form"
                        onChange={({ target }) => {
                           setName(target.value);
                        }}
                    />
                    <Input
                        value={email}
                        required
                        placeholder="E-mail"
                        type="email"
                        form="contact-form"
                        onChange={({ target }) => {
                            setEmail(target.value);
                        }}
                        />
                    <Input
                        value={phone}
                        required
                        placeholder="DDD+Telefone"
                        type="tel"
                        minLength={8}
                        pattern="([0-9]{2}|\([0-9]{2}\)) ?9?[0-9]{4}( |-)?[0-9]{4}"
                        form="contact-form"
                        onChange={({ target }) => {
                            setPhone(target.value);
                        }}
                        />
                    <Input
                        required
                        placeholder="Attach PDF"
                        type="file"
                        accept="application/pdf"
                        form="contact-form"
                        onChange={event => {
                            handleFileUpload(event);
                        }}
                        />
                    <BotaoCallToAction>Entrar em contato</BotaoCallToAction>
                </form>
            </SectionLeft>
            <SectionRight>
                <Img height="128px" width="128px" src={WomanImg} alt="Desenho de mulher com máscara e bem vestida, mostrando o formulário."/>
                <ImgMobile height="128px" width="128px" src={WomanImgMobile} alt="Desenho de mulher com máscara e bem vestida, com os braços cruzados."/>
            </SectionRight>
        </Section>
    )
}

export default ParceiroSection;