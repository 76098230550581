import styled from 'styled-components';
import Card from '../interfaces/Card';
import { devices } from '../helpers/devices';

const StyledCard = styled.div<{ cardColor: string }>`
    margin: 0 .8rem;
    max-width: 380px;
    max-height: 420px;
    border-radius: 20px;
    background: ${props => props.cardColor};

    @media ${devices.tablet} {
        margin: 0 .5rem;
    }

    @media ${devices.mobileL} {
        max-width: 320px;
    }
    `;

const Flex = styled.div<{ titleColor: string, textColor: string }>`
    display: flex;
    flex-direction: column;
    max-height: 420px;

    & h2 {
        margin: 0 3rem .5rem 3rem;
        color: ${props => props.titleColor};
        font-size: 1.5rem;
        font-weight: bold;
        flex-grow: 1;
    }

    & i, & p {
        margin: 0 3rem .4rem 3rem;
        min-height: 134px;
        max-height: 134px;
        font-size: .9rem;
        color: ${props => props.textColor};
        flex-grow: 3;
        overflow: auto;
    }

    @media ${devices.tablet} {
        & h2 {
            margin: 0 2rem 2rem 2rem;
        }

        & i, & p {
            min-height: 120px;
            max-height: 120px;
            margin: 0 2rem .2rem 2rem;
        }
    }
    `;

const Img = styled.div<{ imageUrl: string, cardColor: string }>`
    border-radius: 20px 20px 0 0;
    width: 100%;
    min-height: 200px;
    flex-grow: 2;
    background: linear-gradient(to bottom, transparent 60%, ${props => props.cardColor}),
        url(${props => props.imageUrl});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `;

const Spacer = styled.div<{ cardColor: string }>`
    border-radius: 20px 20px 0 0;
    min-height: 130px;
    background: ${props => props.cardColor};
    flex-grow: 2;
    `;

interface CardOptions {
    isItalic: boolean,
    cardColor: string,
    titleColor: string,
    textColor: string,
}

interface Props {
    card: Card,
    cardOptions: CardOptions,
}

const CardComponent = ({ card, cardOptions }: Props): JSX.Element => {
    return (
        <StyledCard className="card" cardColor={cardOptions.cardColor}>
            <Flex className="flex-div" titleColor={cardOptions.titleColor} textColor={cardOptions.textColor}>
                {card.imageUrl !== undefined ?
                    <Img imageUrl={card.imageUrl} cardColor={cardOptions.cardColor}/>
                : <Spacer cardColor={cardOptions.cardColor}/>}
                <h2>{card.title}</h2>
                {cardOptions.isItalic ? <i>"{card.text}"</i> : <p>{card.text}</p>}
                {card.author !== undefined ? <i><strong>{card.author}</strong></i> : null}
            </Flex>
        </StyledCard>
    );
};

export default CardComponent;