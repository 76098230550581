import styled from 'styled-components';

const Arrow = styled.div`
    display: block;
    color: ${props => props.theme.white1};
    mix-blend-mode: difference;

    &::before{
        color: ${props => props.theme.gray3}
    }
    `;

interface Props {
    className?: string;
    style?: React.CSSProperties;
    onClick?: React.MouseEventHandler<any>;
};

const PrevArrow = ({ className, style, onClick }: Props): JSX.Element => (
    <Arrow
        className={className}
        style={{...style}}
        onClick={onClick}
    />
);

export default PrevArrow;