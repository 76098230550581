import Header from '../components/Header';
import MainSection from '../components/MainSection';
import ParceiroSection from '../components/ParceiroSection';
import Footer from '../components/Footer';

const Formulario = (): JSX.Element => (
    <>
        <Header />
        <MainSection fadeDown={true} height={200}/>
        <ParceiroSection />
        <Footer />
    </>
);

export default Formulario;