import { devices } from '../../helpers/devices';
import styled, { keyframes } from "styled-components";
import { ReactComponent as IconMenu } from "../../assets/IconMenu.svg";

const slide = keyframes`
    from {
        opacity: 0;
        transform: translateX(50%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
`;

const ContainerMobile = styled.div<{ delay: string }>`
  position: fixed;
  height: 100%;
  width: 35%;
  top: 0;
  right: 0;
  padding: 0.3rem 0.5rem;
  box-shadow: 0.1px 0 0 0.1px ${props => props.theme.gray3};
  background-color: ${props => props.theme.bgColor1};
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0;
  opacity: 1;
  z-index: 9;
  animation: ${slide} 1.5s 1 both ${props => props.delay};
  overflow: auto;

  & h3 {
    color: ${props => props.theme.callToActionButtonColor};
  }

  @media ${devices.mobileM} {
    width: 60%;
    display: block;
  }

  @media ${devices.mobileL} {
    width: 50%;
    display: block;
  }

  @media ${devices.tablet} {
    display: block;
  }
`;

const MenuButton = styled.button`
  border: none;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;

  & svg {
    width: 30px;
    height: 30px;
    fill: ${props => props.theme.callToActionButtonColor};
  }
`;

interface Props {
  showHeader: boolean;
  handleShowHeader: () => void;
  children: React.ReactNode;
}

const OpenMenu = ({
  showHeader,
  handleShowHeader,
  children,
}: Props): JSX.Element | null => {
  if (showHeader) {
    return (
      <ContainerMobile delay="1ms">
        <MenuButton id="handleMenu" onClick={() => handleShowHeader()}>
          <IconMenu />
        </MenuButton>
        {children}
      </ContainerMobile>
    );
  } else {
    return null;
  }
};

export default OpenMenu;
