import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Button = styled.button<{ fullWidth: boolean }>`
    display: inline;
    padding: 1rem 2rem;
    width: ${props => props.fullWidth ? "100%" : "max-content"};
    background-color: ${props => props.theme.callToActionButtonColor};
    color: ${props => props.theme.grey2};
    font-weight: bold;
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;

    &:hover {
        background: ${props => props.theme.callToActionButtonHoverColor};
        transition: 400ms;
    }
    `;

const StyledLink = styled(Link)`
    text-decoration: none;
    `;

const A = styled.a`
    text-decoration: none;
    `;

interface LinkOptions {
    attachLink: string,
    target?: string,
    isRouter: boolean,
}

interface Props {
    fullWidth?: boolean,
    LinkOptions?: LinkOptions,
    children: React.ReactNode,
}

const BotaoCallToAction = ({ fullWidth = false, LinkOptions = {attachLink:"", isRouter:true, target:"_self"} , children }: Props): JSX.Element => {
    if (LinkOptions.attachLink === "") {
        return <Button fullWidth={fullWidth}>{children}</Button>
    } else {
        if (LinkOptions.isRouter) {
            return (
                <StyledLink to={LinkOptions?.attachLink}>
                    <Button fullWidth={fullWidth}>{children}</Button>
                </StyledLink>
            )
        } else {
            return (
                <A
                    href={LinkOptions.attachLink}
                    target={LinkOptions.target}
                    rel={LinkOptions.target !== "_self" ? "noreferrer noopener" : undefined}>
                    <Button fullWidth={fullWidth}>{children}</Button>
                </A>
            )
        }
    }
}

export default BotaoCallToAction;