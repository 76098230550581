import { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';
import { devices, size } from '../helpers/devices';
import Card from '../interfaces/Card';
import CardComponent from './CardComponent';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

const Section = styled.section`
    width: 100%;
    background: ${props => props.theme.bgColor2};
    padding: 0 2rem 6rem 2rem;

    & > h2 {
        text-align: center;
        padding-top: 3rem;
        margin-bottom: 3rem;
        color: ${props => props.theme.headerTextColor1};
        font-size: 1.875rem;
        font-weight: bold;
    }

    @media ${devices.laptop} {
        padding-top: 9rem;
    }
    `;

const TempPadding = styled.div`
    height: 0;

    @media ${devices.tablet} {
        height: 150px;
    }
    `;

const CardsSection = (): JSX.Element => {
    const [cards, setCards] = useState<Card[]>([
        {
            title: "Lorem Ipsum",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus",
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.images.express.co.uk%2Fimg%2Fdynamic%2F22%2F590x%2FUS-dollars-976352.jpg%3Fr%3D1529405434284&f=1&nofb=1',
        },
        {
            title: "Lorem Ipsum",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus",
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.images.express.co.uk%2Fimg%2Fdynamic%2F22%2F590x%2FUS-dollars-976352.jpg%3Fr%3D1529405434284&f=1&nofb=1',
        },
        {
            title: "Lorem Ipsum",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus",
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.images.express.co.uk%2Fimg%2Fdynamic%2F22%2F590x%2FUS-dollars-976352.jpg%3Fr%3D1529405434284&f=1&nofb=1',
        },
        {
            title: "Lorem Ipsum",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus",
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.images.express.co.uk%2Fimg%2Fdynamic%2F22%2F590x%2FUS-dollars-976352.jpg%3Fr%3D1529405434284&f=1&nofb=1',
        },
        {
            title: "Lorem Ipsum",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde ea nobis ducimus",
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.images.express.co.uk%2Fimg%2Fdynamic%2F22%2F590x%2FUS-dollars-976352.jpg%3Fr%3D1529405434284&f=1&nofb=1',
        },
    ]);

    const sliderSettings = {
        dots: false,
        slidesToShow: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: Number(size.desktop.replace("px", "")),
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: Number(size.laptopL.replace("px", "")),
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: Number(size.laptop.replace("px", "")),
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: Number(size.tablet.replace("px", "")),
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: Number(size.mobileL.replace("px", "")),
                settings: {
                    slidesToShow: 1,
                }
            },
        ],
    }

    return (
        <Section>
            {/*TODO Remover depois!!! */}
            <TempPadding />
            {/* TODO Descomentar depois!!! */}
            {/* <h2>Blog de Noticias</h2>
            <Slider {...sliderSettings}>
                {cards.map((card, index) => 
                <CardComponent
                    key={index}
                    card={card}
                    cardOptions={
                        {
                            isItalic: false,
                            cardColor: "#FFF",
                            titleColor: "#aa6443",
                            textColor: "#424242",
                        }
                    }
                />
                )}
            </Slider> */}
        </Section>
    );
};

export default CardsSection;