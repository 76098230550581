import styled from 'styled-components';
import Header from '../components/Header';
import MainSection from '../components/MainSection';
import Footer from '../components/Footer';
import { devices } from '../helpers/devices';

const Section = styled.section`
    margin: 0 8rem 2rem 8rem;
    height: 60vh;

    & article {
        margin-bottom: 3rem;
    }

    & h2 {
        color: ${props => props.theme.headerTextColor1};
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    & a {
        display: block;
        width: max-content;
        margin-bottom: .5rem;
    }

    & p {
        margin-top: 1rem;
        color: ${props => props.theme.gray2}
    }

    @media ${devices.laptop} {
        margin: 0 2rem 1rem 2rem;
    }
    `;

const Erro404 = (): JSX.Element => (
    <>
        <Header />
        <MainSection fadeDown={true} height={200}/>
        <Section>
            <h2>Esta página não existe neste site!</h2>
            <p>Use o menu acima para voltar a navegar pelo site.</p>
        </Section>
        <Footer />
    </>
);

export default Erro404;