import Footer from '../components/Footer';
import Header from '../components/Header';
import MainSection from '../components/MainSection';
import SobreSection from '../components/SobreSection';

const Sobre = (): JSX.Element => (
    <>
        <Header />
        <MainSection fadeDown={true} height={200} />
        <SobreSection />
        <Footer />
    </>
);

export default Sobre;