import styled from 'styled-components';
import { devices } from '../helpers/devices';
import Person1 from '../assets/p4.png';
import Person2 from '../assets/p3.png';
import Person3 from '../assets/p1.png';
import Person4 from '../assets/p2.png';
import DascamBuilding from '../assets/tower.png';
import useScrollTo from '../helpers/useScrollTo';

const Section = styled.section`
    margin: 0 8rem 2rem 8rem;

    & section {
        margin-bottom: 4rem;
    }

    & section:last-child {
        margin-bottom: 2rem;
    }

    & h2 {
        color: ${props => props.theme.headerTextColor1};
        margin-bottom: 1rem;
        font-size: 3rem;
    }

    & h3 {
        color: ${props => props.theme.headerTextColor1};
        margin-bottom: .4rem;
        font-size: 1.875rem;
    }

    & a {
        display: block;
        width: max-content;
        margin-bottom: .5rem;
    }

    & p {
        margin-top: 1rem;
        color: ${props => props.theme.gray2}
    }

    @media ${devices.laptop} {
        margin: 0 2rem 0 2rem;

        & section:last-child {
            margin-bottom: 0;
        }
    }
    `;

const PersonShowcaseBox = styled.div`
    display: flex;

    @media ${devices.tablet} {
        flex-direction: column;
        align-items: center;
    }
    `;

const PersonShowcaseDescBox = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 3rem;

    & a {
        display: inline;
    }

    @media ${devices.tablet} {
        padding: 0;
    }
    `;

const PersonShowcaseImgBox = styled.div`
    flex: 1;
    `;

const Person = styled.img`
    height: auto;
    width: 241px;
    `;

const CompanyShowcaseBox = styled.div`
    display: flex;

    @media ${devices.tablet} {
        flex-direction: column-reverse;
        align-items: center;
    }
    `;

const CompanyShowcaseDesc = styled.div`
    flex: 2;
    `;

const CompanyShowcaseImg = styled.div`
    flex: 1;
    padding-right: 2rem;

    @media ${devices.tablet} {
        padding-right: 0;
        margin-top: 2rem;
    }
    `;

const DascamBuildingImg = styled.img`
    height: 100%;
    width: 100%;

    @media ${devices.tablet} {
        position: relative;
        bottom: -5px;
    }
    `;

const SobreSection = (): JSX.Element => {
    const headingRef = useScrollTo<HTMLHeadingElement>('', { behavior: "smooth", block: "start" });

    return (
        <Section>
            <section>
                <h2 ref={headingRef}>Conheça a Dascam</h2>
                <PersonShowcaseBox>
                    <PersonShowcaseDescBox>
                        <h3>Nilo Moreira da Silva</h3>
                        <p>Trabalhou no departamento de câmbio de diversos bancos e corretoras. Em 1975 assumiu a chefia do departamento internacional do Banco Intercontinental de Investimento – Brasilinvest. Em 1980 passou a gerência do departamento de câmbio da SN-Crefisul até 1985, transferindo-se, então, para a Corretora Souza Barros onde exerceu o mesmo cargo até 1989. Posteriormente, prestou seus serviços a Corretora Patente até 1992 quando foi fundada a DASCAM.</p>
                        <p>Email: <a href="mailto:nilo.moreira@dascam.com.br">nilo.moreira@dascam.com.br</a></p>
                    </PersonShowcaseDescBox>
                    <PersonShowcaseImgBox>
                        <Person width="200px" height="300px" src={Person1}/>
                    </PersonShowcaseImgBox>
                </PersonShowcaseBox>
                <PersonShowcaseBox>
                    <PersonShowcaseDescBox>
                        <h3>Sérgio Luiz Bastos Brotto</h3>
                        <p>Profundo conhecedor de toda a legislação cambial e tributária a ela relacionada, tem grande prestígio e reconhecimento no mercado financeiro. Foi chefe do departamento de câmbio da Unibanco Corretora entre 1976 e 1979. Nesse ano assumiu a gerência do departamento de câmbio da Real Corretora até 1983, quando foi ocupar o mesmo cargo na Novação Corretora, tendo sido promovido a Diretor de Câmbio em 1989, permanecendo até 1992 quando passou a integrar a Diretoria da DASCAM.</p>
                        <p>Email: <a href="mailto:sergio.brotto@dascam.com.br">sergio.brotto@dascam.com.br</a></p>
                    </PersonShowcaseDescBox>
                    <PersonShowcaseImgBox>
                        <Person width="200px" height="300px" src={Person2}/>
                    </PersonShowcaseImgBox>
                </PersonShowcaseBox>
                <PersonShowcaseBox>
                    <PersonShowcaseDescBox>
                        <h3>Antonio D'amico</h3>
                        <p>Sua larga experiência profissional foi adquirida por intermédio de sua atuação em corretoras de maior expressão no mercado de câmbio, destacando-se suas passagens pela Corretora Souza Barros e Corretora Patente. Em 1992 transferiu-se para a DASCAM.</p>
                        <p>Email: <a href="mailto:damico.antonio@dascam.com.br">damico.antonio@dascam.com.br</a></p>
                    </PersonShowcaseDescBox>
                    <PersonShowcaseImgBox>
                        <Person width="200px" height="300px" src={Person3}/>
                    </PersonShowcaseImgBox>
                </PersonShowcaseBox>
                <PersonShowcaseBox>
                    <PersonShowcaseDescBox>
                        <h3>Alessandro Del Grande</h3>
                        <p>Com vasto conhecimento em regulamentação relacionada em operações de câmbio e de capitais internacionais, advogado com especialização em direito tributário, atua na estruturação de operações avaliando os impactos com foco multidisciplinar. Alessandro presta consultoria em diversos assuntos, incluindo investimento estrangeiro, empréstimos, arrendamentos e financiamentos, atualmente é o Consultor Sênior da Dascam.</p>
                        <p>Email: <a href="mailto:alessandro.grande@dascam.com.br">alessandro.grande@dascam.com.br</a></p>
                    </PersonShowcaseDescBox>
                    <PersonShowcaseImgBox>
                        <Person width="200px" height="300px" src={Person4}/>
                    </PersonShowcaseImgBox>
                </PersonShowcaseBox>
            </section>
            <section>
                <CompanyShowcaseBox>
                    <CompanyShowcaseImg>
                        <DascamBuildingImg width="500px" height="1000px" src={DascamBuilding}/>
                    </CompanyShowcaseImg>
                    <CompanyShowcaseDesc>
                        <h2>Sobre a Empresa</h2>
                        <p>Construir parcerias estratégicas, investir em potencial humano, apostar no diferencial de seus profissionais, ter visão estratégica de longo prazo, permitiram que a DASCAM conquistasse posição privilegiada em seu mercado de atuação, proporcionando soluções inovadoras aos seus clientes.</p>
                        <p>A DASCAM entende que qualidade é o principio básico do negócio, e se empenha ao máximo para atender os requerimentos do cliente e obter a sua plena satisfação.</p>
                        <p>A crescente diversificação das atividades no mercado financeiro, a dinâmica do acompanhamento contínuo dos regulamentos e procedimentos que regem o mercado cambial, de comércio exterior e capitais estrangeiros, demandam um aperfeiçoamento constante de sua equipe de colaboradores, que trabalha ativamente para proporcionar um atendimento diferenciado aos seus clientes.</p>
                        <p>A equipe de profissionais é formada por pessoal técnico, frequentemente treinado e valorizado. A realização periódica de treinamentos gerenciais, cursos de aperfeiçoamento, seminários, palestras e outros tipos de atividades educacionais, contribuem para a manutenção desse verdadeiro patrimônio.</p>
                        <p>A fim de proporcionar e assegurar a total qualidade dos serviços prestados, o escritório dispõe de modernos programas e sistemas informatizados e de comunicação, frutos de sólidos investimentos em tecnologia. A DASCAM possui uma longa tradição na assessoria e consultoria a empresas, e tem se destacado fortemente no desenvolvimento de projetos que requerem grande conhecimento técnico. A condução de complexas operações de fluxo de capitais e outras que necessitam de autorizações de órgãos governamentais, é executada com a segurança de quem tem experiência comprovada na área.</p>
                        <p>A expertise, adquirida por seus diretores, ao longo de 30 anos de destacada atuação na área de câmbio, coloca a DASCAM em posição de liderança dentre as corretoras de câmbio, e que lhe permite realizar operações de câmbio que sequer foram normatizadas e, em razão disso, formular procedimentos que, frequentemente, serão adotados pelo mercado, como modelos a ser seguidos. Com certeza, a experiência da DASCAM virá ao encontro das suas necessidades e tornará possível usufruir de toda essa credibilidade e expertise.</p>
                    </CompanyShowcaseDesc>
                </CompanyShowcaseBox>
            </section>
        </Section>
    )
};

export default SobreSection;