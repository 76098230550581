import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

type ScrollOptions = boolean | ScrollIntoViewOptions | undefined;

export default function useScrollTo<Element extends HTMLElement>(linkHash: string, options: ScrollOptions, eraseHash = false) {
    const { pathname, hash } = useLocation();
    const ref = useRef<Element>(null);

    useEffect(() => {
        if (hash === linkHash) {
            if (ref != null) {
                ref.current!.scrollIntoView(options);
                if (eraseHash) {
                    window.location.hash = '';
                }
            }
        }
    }, [pathname, hash, linkHash, options, eraseHash]);

    return ref;
};