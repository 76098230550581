import Background from '../assets/bg site 2.jpg';
import styled from 'styled-components';
import { devices } from '../helpers/devices';

const Section = styled.section<{ height: number }>`
    width: 100%;
    height: ${props => props.height}px;
    background-image: url('${Background}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    grid-template-rows: repeat(4, minmax(10px, 1fr));
    grid-row-gap: 1rem;

    @media ${devices.laptop} {
        position: relative;
        height: ${props => props.height - 100}px;
    }

    @media ${devices.mobileL} {
        grid-template-columns: 4fr 1fr 1fr;
    }
    `;

const SectionFadeDown = styled(Section)`
    background:
        linear-gradient(to bottom, transparent 20%, ${props => props.theme.white1}),
        url('${Background}')
        ${props => props.height < 600 ? "right" : "bottom right"}
        no-repeat;
    background-size: cover;
    `;

const H1 = styled.h1`
    grid-row-start: 2;
    align-self: end;
    padding-left: 6rem;
    color: ${props => props.theme.headerTextColor1};
    font-size: 2.25rem;
    font-weight: bold;

    @media ${devices.laptop} {
        color: ${props => props.theme.headerTextColorAlt1};
        font-size: 1.5rem;
        padding-left: 3rem;
        grid-column-start: 1;
    }

    @media ${devices.tablet} {
        padding-left: 3rem;
    }

    @media ${devices.mobileL} {
        padding-left: 2rem;
    }
    `;

const HeroText = styled.div`
    grid-row-start: 3;
    background: ${props => props.theme.bgColor1};
    width: 585px;
    height: 203px;
    border-radius: 0 20px 20px 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > p {
        font-size: 1.2rem;
        padding: 1rem 1rem 1rem 4rem;
        color: ${props => props.theme.white1};
    }

    @media ${devices.laptop} {
        width: 400px;
        height: 203px;

        & > p {
            font-size: 1rem;
        }
    }

    @media ${devices.tablet} {
        width: 400px;
        height: 200px;

        & > p {
            padding: 1rem 1rem 1rem 6rem;
            font-size: 1rem;
        }
    }

    @media ${devices.mobileL} {
        width: 100%;
        height: 150px;

        & > p {
            padding: .5rem .5rem .5rem 2rem;
            font-size: .65rem;
        }
    }
    `;

// const LoginButton = styled.img`
//     position: relative;
//     top: .6rem;
//     grid-column-start: 3;
//     width: 80px;
//     height: 80px;

//     @media ${devices.laptop} {
//         width: 80px;
//         height: 80px;
//         top: 20px;
//         right: 20px;
//     }

//     @media ${devices.tablet} {
//         display: none;
//     }
//     `;

interface Props {
    fadeDown: boolean,
    height?: number,
}

const MainSection = ({ fadeDown, height = 600 }: Props): JSX.Element => {
    if (!fadeDown) {
        return (
            <Section height={height}>
                <H1>Especialistas em operações que movem o mundo</H1>
                <HeroText>
                    <p>A Dascam Corretora de Câmbio Ltda., que atua há mais de 30 anos, é o resultado da união de profissionais com competência reconhecida, altamente especializados, equipe qualificada e capacitada a solucionar as necessidades do cliente.</p>
                </HeroText>
                
            </Section>
        )
    } else {
        return (
            <SectionFadeDown height={height}>
                {/* <LoginButton width="128px" height="128px" src={LoginBtn} alt="Botão de login"/> */}
            </SectionFadeDown>
        )
    }
}

export default MainSection;