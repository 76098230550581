import { Fragment } from 'react';
import styled from 'styled-components';
import Servico from '../interfaces/Servico';
import Arrow from '../assets/Seta Transparente.png';
import { devices } from '../helpers/devices';

const StyledServico = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

    & > h3 {
        padding: .2rem .6rem;
        font-size: 1.3rem;
        color: ${props => props.theme.white1};
        background: ${props => props.theme.bgColor1};
        border-left: .8rem ${props => props.theme.callToActionButtonColor} solid;
        align-self: flex-start;
        width: 60%;
    }
    `;

const NomeServico = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin: .8rem 0 0 2.7rem;
    padding-left: .5rem;
    border: none;
    border-bottom: 1px ${props => props.theme.gray3} solid;
    background: ${props => props.theme.white1};
    cursor: pointer;
    outline: none;

    & > p {
        text-align: left;
        color: ${props => props.theme.gray4};
        margin-bottom: .5rem;
    }

    &:hover {
        background-color: ${props => props.theme.white3};
    }

    @media ${devices.laptop} {
        margin: .5rem 1rem;
    }

    @media ${devices.mobileL} {
        margin-top: 1.8rem;
    }
    `;

const Dropdown = styled.div`
    padding: 0 3.2rem 1rem 2rem;
    display: none;
    overflow: hidden;
    width: 100%;
    max-height: 0;
    transition: max-height 0.3s ease-out;

    & > p {
        margin-top: 1rem;
        line-height: 23px;
        font-size: .9rem;
        text-align: justify;
    }

    @media ${devices.mobileL} {
        padding: 0 3.1rem 0 1rem;
    }
    `;

const ArrowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: ${props => props.theme.callToActionButtonColor};
    `;

const ArrowDown = styled.img`
    width: 20px;
    height: 20px;
    transition: transform 0.5s ease;
    transform: rotate(0deg) translate(0);
    `;

interface Props {
    servico: Servico;
}

const ServicoComponent = ({ servico }: Props): JSX.Element => {
    const toggleDisplay = (element: HTMLElement): void => {
        const elementStyle = window.getComputedStyle(element, null);

        if (elementStyle.display === "block") {
            element.style.display = "none"
            element.style.overflow = "hidden";
            element.style.maxHeight = "0";
        } else {
            element.style.display = "block"
            element.style.overflow = "visible";
            element.style.maxHeight = `${element.scrollHeight}px`;
        }
    };

    const toggleRotation = (element: HTMLElement): void => {
        const elementStyle = window.getComputedStyle(element, null);

        // Se rotação = 0
        if (elementStyle.transform === "matrix(1, 0, 0, 1, 0, 0)") {
            // Equivale a = transform: scaleY(-1) skew(-2deg)
            element.style.transform = "matrix(1, 0, -0.0349208, -1, 0, 0)";
        } else {
            // Equivale a = transform: rotate(0deg)
            element.style.transform = "matrix(1, 0, 0, 1, 0, 0)";
        }
    };

    const selectDropdown = (target: HTMLElement): HTMLElement | null => {
        let selection = null;

        switch (target.tagName.toUpperCase()) {
            case "BUTTON":
                selection = target.nextElementSibling as HTMLElement;
                break;
            case "IMG":
                selection = target.parentElement?.parentElement?.nextElementSibling as HTMLElement;
                break;
            case "DIV":
            case "P":
                selection = target.parentElement?.nextElementSibling as HTMLElement;
                break;
            default:
                return null;
        }

        if (selection.tagName.toUpperCase() === "DIV") {
            return selection;
        }

        return null;
    };

    const selectArrow = (target: HTMLElement): HTMLElement | null => {
        switch (target.tagName.toUpperCase()) {
            case "IMG":
                return target;
            case "DIV":
                return target.firstElementChild as HTMLElement;
            case "BUTTON":
                return target.lastElementChild?.firstElementChild as HTMLElement;
            case "P":
                return target.parentElement?.lastElementChild?.firstElementChild as HTMLElement;
            default:
                return null;
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const dropdown = selectDropdown(event.target as HTMLElement);
        const arrow = selectArrow(event.target as HTMLElement);

        if (dropdown != null && arrow != null) {
            toggleDisplay(dropdown);
            toggleRotation(arrow);
        }
    };

    return (
        <StyledServico>
            <h3>{servico.title}</h3>
            {servico.subItem.map((item, index) => {
                const dropdownContent = item.dropdownContent.map((text, index) => (
                    <p key={index}>{text}</p>
                ));

                return (
                    <Fragment key={index}>
                        <NomeServico title="Dê um click para expandir/contrair" onClick={event => handleClick(event)}>
                            <p>{item.title}</p>
                            <ArrowContainer>
                                <ArrowDown src={Arrow} alt="Seta para baixo que abre um dropdown."/>
                            </ArrowContainer>
                        </NomeServico>
                        <Dropdown className="serviço-dropdown">
                            {dropdownContent}
                        </Dropdown>
                    </Fragment>
                )
            })}
        </StyledServico>
    )
};

export default ServicoComponent;