import { useState } from 'react';
import styled from 'styled-components';
import { devices } from '../helpers/devices';
import useScrollTo from '../helpers/useScrollTo';

const Section = styled.section`
    margin: 0 8rem 2rem 8rem;

    & article {
        margin-bottom: 3rem;
    }

    & h2 {
        color: ${props => props.theme.headerTextColor1};
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    & a {
        display: block;
        width: max-content;
        margin-bottom: .5rem;
    }

    & p {
        margin-top: 1rem;
        color: ${props => props.theme.gray2}
    }

    @media ${devices.laptop} {
        margin: 0 2rem 1rem 2rem;
    }
    `;

const List = styled.div`
    & p {
        margin-top: .2rem;
    }
    `;

const ServicosCompletoSection = (): JSX.Element => {
    const [demonstrativos, setDemonstrativos] = useState([
        {
            description: "Demonstrativos de 30 de junho de 2023",
            link: "https://dascam.s3.amazonaws.com/demostrativos/06_2023.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2022",
            link: "https://dascam.s3.amazonaws.com/demostrativos/31_12_2022.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2022",
            link: "https://dascam.s3.amazonaws.com/demostrativos/30_06_2022.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2021",
            link: "https://dascam.s3.amazonaws.com/demostrativos/12_2021.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2021",
            link: "https://dascam.s3.amazonaws.com/demostrativos/06_2021.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2020",
            link: "https://dascam.s3.amazonaws.com/demostrativos/31_12_2020.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2020",
            link: "http://www.dascam.com.br/2014/demonstrativo/s21__s20_30.06.2020.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2019",
            link: "http://www.dascam.com.br/2014/demonstrativo/s19__31.12.2019.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2019",
            link: "http://www.dascam.com.br/2014/demonstrativo/s2__demonstrativo-30-06-19-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2018",
            link: "http://www.dascam.com.br/2014/demonstrativo/s4__demonstrativo-31-12-18-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2018",
            link: "http://www.dascam.com.br/2014/demonstrativo/s3__demonstrativo-30-06-18-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2017",
            link: "http://www.dascam.com.br/2014/demonstrativo/s7__demonstracoes_contabeis_31-12-2017-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2017",
            link: "http://www.dascam.com.br/2014/demonstrativo/s10__demonstrativo_demonstracoes_contabeis_%2030-06-17-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2016",
            link: "http://www.dascam.com.br/2014/demonstrativo/s11__demonstrativo_demonstracoes_contabeis_%2031-12-16-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2016",
            link: "http://www.dascam.com.br/2014/demonstrativo/s12__demonstrativo_demonstracoes_contabeis_%2030-06-16-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2015",
            link: "http://www.dascam.com.br/2014/demonstrativo/s13__demonstrativo_demonstracoes_contabeis_%2031-12-15-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2015",
            link: "http://www.dascam.com.br/2014/demonstrativo/s17__demonstrativo_demonstracoes_contabeis_%2030-06-15-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 31 de dezembro de 2014",
            link: "http://www.dascam.com.br/2014/demonstrativo/s15__demonstrativo_demonstracoes_contabeis_%2031-12-14-auditadas.pdf",
        },
        {
            description: "Demonstrativos de 30 de junho de 2014",
            link: "http://www.dascam.com.br/2014/demonstrativo/s16__demonstrativo_demonstracoes_contabeis_%2030-06-14-auditadas.pdf",
        },
    ]);
    const politicaDeSegurancaRef = useScrollTo<HTMLHeadingElement>("#politica_de_segurança", { behavior: "smooth", block: "start"});
    const demonstrativosFinanceirosRef = useScrollTo<HTMLHeadingElement>("#demonstrativos_financeiros", { behavior: "smooth", block: "start" });

    return (
        <Section>
            <article>
                <h2 ref={demonstrativosFinanceirosRef}>Demonstrativos Financeiros</h2>
                {demonstrativos.map(item => (
                    <a key={item.link} href={item.link} target="_blank" rel="noreferrer noopener">{item.description}</a>
                ))}
            </article>
        </Section>
    )
};

export default ServicosCompletoSection;